export default [{
        name: "HTML5 • CSS • JavaScript",
        description: "Fundamental Web Development",
        icon: ['fab', "html5"],
        color: "#EC5F38"
    },
    {
        name: "NuxtJS • VueJS • Tailwind",
        description: "Frontend Web Development",
        icon: ['fab', "vuejs"],
        color: "#41B883"
    },
    {
        name: "NodeJS • MongoDB • ExpressJS",
        description: "Backend Web Development",
        icon: ['fab', "node-js"],
        color: "#659E5A"
    },
    {
        name: "GitHub Actions CI/CD",
        description: "Continues Integration",
        icon: ['fas', "code-branch"],
        color: "#3EB1CA"
    },
    {
        name: "Cloudflare Workers • Vercel",
        description: "Edge computing",
        icon: ['fas', "bolt"],
        color: "#F6821F"
    },
    {
        name: "Adobe XD • Figma",
        description: "UI/UX Design",
        icon: ['fas', "pencil-ruler"],
        color: "rgb(180, 141, 230)"
    },
    {
        name: "TypeScript",
        description: "Software Development",
        icon: ['fas', "code"],
        color: "#007ACC"
    },
    {
        name: "Linux • Docker • NGINX",
        description: "Server Administration",
        icon: ['fab', "linux"],
        color: "#fff"
    },
    {
        name: "Git • GitHub",
        description: "Version Control",
        icon: ['fab', "git-alt"],
        color: "#F75036"
    },
]