export default [
    {
        name: "WebCrate",
        website: "https://webcrate.app",
        github: "WebCrateApp/webcrate",
        description: {
            de: "Organisiere, verwalte und teile Links aus dem gesamten Web mit WebCrate. Es ist mehr als nur ein Lesezeichen-Tool.",
            en: "Group, organize and share links from around the web with WebCrate. It's more than just a bookmarking tool."
        },
        language: "Node.js • Nuxt • Deta Space"
    },
    {
        name: "Aletsch VPN",
        website: "https://aletsch.app",
        github: "AletschVPN",
        description: {
            de: "Installiere und verwalte deinen eigenen schnellen und privaten VPN auf deinem eigenen Server mit Aletsch.",
            en: "Setup and manage your own fast and privacy friendly VPN hosted on your own server with Aletsch."
        },
        language: "Electron • Wireguard • Vue"
    },
    {
        name: "MailPal",
        website: 'https://mailpal.cc',
        description: {
            de: "Generiere eine einmalige wegwerf E-Mail-Adresse oder erstelle unbegrenzt viele E-Mail-Adresse um deine Privatsphäre vor Firmen und Diensten zu schützen. Deaktiviere eine der Adressen jederzeit wenn du zu viele Emails bekommst.",
            en: "Need a throw away email or don't want to share your personal email with the whole world? MaiPal's email forwarding service let's you create unlimited email aliases."
        },
        language: "Vue.js • Node.js • Haraka"
    },
    {
        name: "Readme.Fish",
        website: 'https://readme.fish',
        github: 'BetaHuhn/readme-fish',
        description: {
            de: "Generiert dynamisch eine rasend schnelle statische HTML-Version jeder öffentlichen GitHub README.md Datei und speichert sie mit Cloudflare Workers auf einem CDN.",
            en: "Dynamically generates a blazingly fast static HTML version of any public GitHub repos README.md file and caches it on a CDN using Cloudflare Workers."
        },
        language: "Cloudflare Workers • GitHub API"
    },
    {
        name: "Termina.link",
        website: 'https://termina.link',
        description: {
            de: "Zeichne eine Terminalsitzung auf und teile sie als link mit nur einem Befehl. Ende-zu-Ende verschlüsselt und anonym.",
            en: "Record and share your terminal session as a link with one command. End-to-end encrypted and anonymous."
        },
        language: "Node.js • NPM"
    },
    {
        name: "ackee-report",
        github: "BetaHuhn/ackee-report",
        description: {
            de: "CLI tool zum Generieren von Leistungsberichten von Webseiten mit dem analytics tool Ackee.",
            en: "CLI tool to generate performance reports of websites using the self-hosted analytics tool Ackee."
        },
        language: "Node.js • NPM"
    },
    {
        name: "drkmd.js",
        demo: "https://codepen.io/BetaHuhn/pen/oNxdBzK",
        github: "BetaHuhn/drkmd.js",
        description: {
            de: "Einfache Bibliothek zur Integration einer Dark-Mode- / Light-Mode-Logik in jede Webseite.",
            en: "Simple library to integrate a dark-mode/light-mode logic with any website."
        },
        language: "JavaScript • CSS • NPM"
    },
    {
        name: "feedback-js",
        demo: "https://codepen.io/BetaHuhn/pen/MWebeXp",
        github: "BetaHuhn/feedback-js",
        description: {
            de: "Einfaches modernes Feedback-Modal für jede Webseite.",
            en: "Simple modern feedback modal for any website."
        },
        language: "JavaScript • CSS • NPM"
    },
    {
        name: "qrgen.cc",
        demo: "https://qrgen.cc",
        github: "BetaHuhn/qrgen",
        description: {
            de: "QrGenc.cc ist ein service mit dem man einfach und schnell QR-Codes und kurz-URLs erstellen kann",
            en: "QrGen.cc is a free service that lets you create QR-Codes and shortened URLs from any link quickly and easily"
        },
        language: "Vue.js • Node.js"
    },
    {
        name: "repo-file-sync-action",
        github: "BetaHuhn/repo-file-sync-action",
        description: {
            de: "GitHub Action, um Dateien wie Action-Workflows oder ganze Verzeichnisse zwischen mehreren Repositorys zu synchronisieren.",
            en: "GitHub Action to keep files like Action workflows or entire directories in sync between multiple repositories."
        },
        language: "JavaScript • GitHub Actions"
    }
]